

















import { defineComponent, reactive, ref } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import { useApi } from '@/api';
import { errorCatcher } from '@/composables/errorCatcher';
import { useStore } from '@/store';
import { useRoute, useRouter } from '@/router';

export default defineComponent({
  setup() {
    const toast = useToast();
    const api = useApi();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const loading = ref(false);

    const formRef = ref<{
      validate(): void;
    } | null>(null);

    const values = reactive({
      email: '',
      password: '',
    });

    const rules = {
      email: [(v: string) => !!v || 'Email is required'],
      password: [(v: string) => !!v || 'Password is required'],
    };

    const validForm = ref(false);

    async function onSubmit() {
      if (validForm.value) {
        loading.value = true;
        try {
          const user = await api.auth.login(values);
          store.state.user = user;

          toast.success('Welcome!');

          router.replace({
            path: (route.value.query.from as string) || '/',
          });
        } catch (err) {
          if (err.response) {
            if (err.response.status === 401) {
              toast.error('Invalid email and/or password');
            }
          } else {
            errorCatcher(err);
          }
        } finally {
          loading.value = false;
        }
      } else {
        toast.warning('Some fields are empty or incorrect values');
      }
    }

    return {
      values,
      validForm,
      rules,
      formRef,
      onSubmit,
      loading,
      store,
    };
  },
});
